<template>
</template>
<script setup lang="ts">
import { useBreadcrumbStore } from "~/stores/breadcrumb";
import { useGtmPush } from "~/composables/gtm/push";
const currentRoute = useRoute();
const { gtmPush } = useGtmPush();
const config = useRuntimeConfig();
const { breadcrumb, route: breadcrumbRoute }  = storeToRefs(useBreadcrumbStore());
import { useSeoTagsStore } from "~/stores/seo";
const {seoTags, route: seoTagsRoute } =  storeToRefs(useSeoTagsStore());
onMounted(() => {
    push();
        watch(()=>breadcrumbRoute.value,
            ()=>{ if(breadcrumbRoute.value?.path == currentRoute.path && seoTagsRoute.value?.path == currentRoute.path){
                push() }
            }
        );
        watch(()=>seoTagsRoute.value,
            ()=>{ if(breadcrumbRoute.value?.path == currentRoute.path && seoTagsRoute.value?.path == currentRoute.path){
                push() }
            }
        );
});

const getBreadcrumbItemTitle = (index:number) :string => {
    if(breadcrumb.value && breadcrumb.value[index]){
        return breadcrumb.value[index]?.title ?? '';
    }
    return "";
}

const push = () =>{
    
    console.log("title");
    console.log(seoTags.value.title);
    const datalayerData = {
        event: "virtualPageView",
        environment: config.public.frontendHost,
        language: config.public.language,
        page_title: seoTags.value.title,
        page_category_1: getBreadcrumbItemTitle(0),
        page_category_2: getBreadcrumbItemTitle(1),
        page_category_3: getBreadcrumbItemTitle(2),
        page_category_4: getBreadcrumbItemTitle(3),
        pageUrl: window.location.href
    };
    gtmPush(datalayerData)
}
</script>