import { defineStore } from "pinia";
import type { BreadcrumbItems } from "~/interfaces/breadcrumb";
import { type NuxtPage} from "nuxt/schema";

export const STORE_NAME = "breadcrumb";

type BreadcrumbState = {
    _breadcrumb?: BreadcrumbItems;
    _route?: NuxtPage;
}

export const useBreadcrumbStore = defineStore(STORE_NAME, {
    state: () => ({
        _breadcrumb: undefined,
        _route: undefined
    } as BreadcrumbState),
    actions: {
        setBreadcrumb(value:BreadcrumbItems) {
            this._breadcrumb = value;
        },
        setRoute(value: NuxtPage) {
            this._route = value;
        }
    },
    getters: {
        breadcrumb: (state) =>  state._breadcrumb,
        route: (state) => state._route
    }
});
